import { default as energy_45green_45practicesTAO8Vsi5TGMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyWjFECrN5CqMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderZobg8cZlOzMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexo66iefGaffMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamaJoEGTsddvMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderO7eO29nDkQMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesRopwX4pmVXMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93ANwHXNsjQCMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexlkOax1k8sQMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexAzc6WNiLQFMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93VlI7vfu2qbMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexxa5fsfSdmEMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as central_45park_45special_45offerffqUwxx8sJMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue?macro=true";
import { default as ask_45a_45questionogiet3A1eNMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesFXf5TcLWRRMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faq7TWYq2O4aQMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resources0Q2D0wURvSMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexAbF7DJHOc2Meta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsGwynax86m7Meta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersMxKH1u60X4Meta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexUCAf5GhEXxMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexn8lDundAYcMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as index2od3yRQNOJMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexVT7ePUrGn1Meta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexPchsuXB0TpMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexHLhQkGog4DMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexNl4INI7dqzMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationRadjGTScylMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionsXduTANfzGMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeIkVVdi8TNIMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceSNsKhQIFCBMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93qlR0nyBHCPMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexnu57RYBCDKMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexbOsvU140BwMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessJA7we73aTrMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93n4dcrxKhM8Meta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesb0UIGlKgYsMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searcho4hWCZOYBgMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhousesd80V7RzPxMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionXBetEJN0neMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottagebpHyDG65w7Meta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexVhQkBKJsXqMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodslADk4kZwMpMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45uscUov9RAWKzMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsaf8hB8tGLgMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandbox1DB9IsOP8gMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93L2lO8IN5jYMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubLrvdXmAaLrMeta } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubLrvdXmAaLr } from "/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesTAO8Vsi5TGMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesTAO8Vsi5TGMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesTAO8Vsi5TGMeta || {},
    alias: energy_45green_45practicesTAO8Vsi5TGMeta?.alias || [],
    redirect: energy_45green_45practicesTAO8Vsi5TGMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyWjFECrN5CqMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyWjFECrN5CqMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyWjFECrN5CqMeta || {},
    alias: fun_45part_45journeyWjFECrN5CqMeta?.alias || [],
    redirect: fun_45part_45journeyWjFECrN5CqMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderZobg8cZlOzMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderZobg8cZlOzMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderZobg8cZlOzMeta || {},
    alias: homesafe_45colorado_45master_45builderZobg8cZlOzMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderZobg8cZlOzMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexo66iefGaffMeta?.name ?? "about-us",
    path: indexo66iefGaffMeta?.path ?? "/about-us",
    meta: indexo66iefGaffMeta || {},
    alias: indexo66iefGaffMeta?.alias || [],
    redirect: indexo66iefGaffMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamaJoEGTsddvMeta?.name ?? "about-us-our-team",
    path: our_45teamaJoEGTsddvMeta?.path ?? "/about-us/our-team",
    meta: our_45teamaJoEGTsddvMeta || {},
    alias: our_45teamaJoEGTsddvMeta?.alias || [],
    redirect: our_45teamaJoEGTsddvMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderO7eO29nDkQMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderO7eO29nDkQMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderO7eO29nDkQMeta || {},
    alias: preferred_45lenderO7eO29nDkQMeta?.alias || [],
    redirect: preferred_45lenderO7eO29nDkQMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesRopwX4pmVXMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesRopwX4pmVXMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesRopwX4pmVXMeta || {},
    alias: realtor_45resourcesRopwX4pmVXMeta?.alias || [],
    redirect: realtor_45resourcesRopwX4pmVXMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93ANwHXNsjQCMeta?.name ?? "blog-post",
    path: _91post_93ANwHXNsjQCMeta?.path ?? "/blog/:post()",
    meta: _91post_93ANwHXNsjQCMeta || {},
    alias: _91post_93ANwHXNsjQCMeta?.alias || [],
    redirect: _91post_93ANwHXNsjQCMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexlkOax1k8sQMeta?.name ?? "blog-category-category",
    path: indexlkOax1k8sQMeta?.path ?? "/blog/category/:category()",
    meta: indexlkOax1k8sQMeta || {},
    alias: indexlkOax1k8sQMeta?.alias || [],
    redirect: indexlkOax1k8sQMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAzc6WNiLQFMeta?.name ?? "blog",
    path: indexAzc6WNiLQFMeta?.path ?? "/blog",
    meta: indexAzc6WNiLQFMeta || {},
    alias: indexAzc6WNiLQFMeta?.alias || [],
    redirect: indexAzc6WNiLQFMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VlI7vfu2qbMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93VlI7vfu2qbMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93VlI7vfu2qbMeta || {},
    alias: _91_46_46_46slug_93VlI7vfu2qbMeta?.alias || [],
    redirect: _91_46_46_46slug_93VlI7vfu2qbMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexxa5fsfSdmEMeta?.name ?? "campaigns",
    path: indexxa5fsfSdmEMeta?.path ?? "/campaigns",
    meta: indexxa5fsfSdmEMeta || {},
    alias: indexxa5fsfSdmEMeta?.alias || [],
    redirect: indexxa5fsfSdmEMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: central_45park_45special_45offerffqUwxx8sJMeta?.name ?? "central-park-special-offer",
    path: central_45park_45special_45offerffqUwxx8sJMeta?.path ?? "/central-park-special-offer",
    meta: central_45park_45special_45offerffqUwxx8sJMeta || {},
    alias: central_45park_45special_45offerffqUwxx8sJMeta?.alias || [],
    redirect: central_45park_45special_45offerffqUwxx8sJMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionogiet3A1eNMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionogiet3A1eNMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionogiet3A1eNMeta || {},
    alias: ask_45a_45questionogiet3A1eNMeta?.alias || [],
    redirect: ask_45a_45questionogiet3A1eNMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesFXf5TcLWRRMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesFXf5TcLWRRMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesFXf5TcLWRRMeta || {},
    alias: career_45opportunitiesFXf5TcLWRRMeta?.alias || [],
    redirect: career_45opportunitiesFXf5TcLWRRMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faq7TWYq2O4aQMeta?.name ?? "contact-us-faq",
    path: faq7TWYq2O4aQMeta?.path ?? "/contact-us/faq",
    meta: faq7TWYq2O4aQMeta || {},
    alias: faq7TWYq2O4aQMeta?.alias || [],
    redirect: faq7TWYq2O4aQMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resources0Q2D0wURvSMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resources0Q2D0wURvSMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resources0Q2D0wURvSMeta || {},
    alias: homeowner_45resources0Q2D0wURvSMeta?.alias || [],
    redirect: homeowner_45resources0Q2D0wURvSMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexAbF7DJHOc2Meta?.name ?? "contact-us",
    path: indexAbF7DJHOc2Meta?.path ?? "/contact-us",
    meta: indexAbF7DJHOc2Meta || {},
    alias: indexAbF7DJHOc2Meta?.alias || [],
    redirect: indexAbF7DJHOc2Meta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsGwynax86m7Meta?.name ?? "contact-us-our-locations",
    path: our_45locationsGwynax86m7Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsGwynax86m7Meta || {},
    alias: our_45locationsGwynax86m7Meta?.alias || [],
    redirect: our_45locationsGwynax86m7Meta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersMxKH1u60X4Meta?.name ?? "current-offers",
    path: current_45offersMxKH1u60X4Meta?.path ?? "/current-offers",
    meta: current_45offersMxKH1u60X4Meta || {},
    alias: current_45offersMxKH1u60X4Meta?.alias || [],
    redirect: current_45offersMxKH1u60X4Meta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexUCAf5GhEXxMeta?.name ?? "events-event",
    path: indexUCAf5GhEXxMeta?.path ?? "/events/:event()",
    meta: indexUCAf5GhEXxMeta || {},
    alias: indexUCAf5GhEXxMeta?.alias || [],
    redirect: indexUCAf5GhEXxMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexn8lDundAYcMeta?.name ?? "events",
    path: indexn8lDundAYcMeta?.path ?? "/events",
    meta: indexn8lDundAYcMeta || {},
    alias: indexn8lDundAYcMeta?.alias || [],
    redirect: indexn8lDundAYcMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index2od3yRQNOJMeta?.name ?? "homes-location-community",
    path: index2od3yRQNOJMeta?.path ?? "/homes/:location()/:community()",
    meta: index2od3yRQNOJMeta || {},
    alias: index2od3yRQNOJMeta?.alias || [],
    redirect: index2od3yRQNOJMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVT7ePUrGn1Meta?.name ?? "homes-location-community-models-model",
    path: indexVT7ePUrGn1Meta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indexVT7ePUrGn1Meta || {},
    alias: indexVT7ePUrGn1Meta?.alias || [],
    redirect: indexVT7ePUrGn1Meta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPchsuXB0TpMeta?.name ?? "homes-location-community-plans-plan",
    path: indexPchsuXB0TpMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexPchsuXB0TpMeta || {},
    alias: indexPchsuXB0TpMeta?.alias || [],
    redirect: indexPchsuXB0TpMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHLhQkGog4DMeta?.name ?? "homes-location-community-qmi-home",
    path: indexHLhQkGog4DMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexHLhQkGog4DMeta || {},
    alias: indexHLhQkGog4DMeta?.alias || [],
    redirect: indexHLhQkGog4DMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNl4INI7dqzMeta?.name ?? "index",
    path: indexNl4INI7dqzMeta?.path ?? "/",
    meta: indexNl4INI7dqzMeta || {},
    alias: indexNl4INI7dqzMeta?.alias || [],
    redirect: indexNl4INI7dqzMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationRadjGTScylMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationRadjGTScylMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationRadjGTScylMeta || {},
    alias: knolls_45hoa_45informationRadjGTScylMeta?.alias || [],
    redirect: knolls_45hoa_45informationRadjGTScylMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionsXduTANfzGMeta?.name ?? "land-acquisition",
    path: land_45acquisitionsXduTANfzGMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionsXduTANfzGMeta || {},
    alias: land_45acquisitionsXduTANfzGMeta?.alias || [],
    redirect: land_45acquisitionsXduTANfzGMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeIkVVdi8TNIMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placeIkVVdi8TNIMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeIkVVdi8TNIMeta || {},
    alias: brents_45placeIkVVdi8TNIMeta?.alias || [],
    redirect: brents_45placeIkVVdi8TNIMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceSNsKhQIFCBMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceSNsKhQIFCBMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceSNsKhQIFCBMeta || {},
    alias: experience_45lower_45maintenanceSNsKhQIFCBMeta?.alias || [],
    redirect: experience_45lower_45maintenanceSNsKhQIFCBMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93qlR0nyBHCPMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93qlR0nyBHCPMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93qlR0nyBHCPMeta || {},
    alias: _91testimonial_93qlR0nyBHCPMeta?.alias || [],
    redirect: _91testimonial_93qlR0nyBHCPMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexnu57RYBCDKMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexnu57RYBCDKMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexnu57RYBCDKMeta || {},
    alias: indexnu57RYBCDKMeta?.alias || [],
    redirect: indexnu57RYBCDKMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbOsvU140BwMeta?.name ?? "lifestyle-reviews",
    path: indexbOsvU140BwMeta?.path ?? "/lifestyle/reviews",
    meta: indexbOsvU140BwMeta || {},
    alias: indexbOsvU140BwMeta?.alias || [],
    redirect: indexbOsvU140BwMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessJA7we73aTrMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessJA7we73aTrMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessJA7we73aTrMeta || {},
    alias: whats_45lifefullnessJA7we73aTrMeta?.alias || [],
    redirect: whats_45lifefullnessJA7we73aTrMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93n4dcrxKhM8Meta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93n4dcrxKhM8Meta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93n4dcrxKhM8Meta || {},
    alias: _91_46_46_46slug_93n4dcrxKhM8Meta?.alias || [],
    redirect: _91_46_46_46slug_93n4dcrxKhM8Meta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesb0UIGlKgYsMeta?.name ?? "my-favorites",
    path: my_45favoritesb0UIGlKgYsMeta?.path ?? "/my-favorites",
    meta: my_45favoritesb0UIGlKgYsMeta || {},
    alias: my_45favoritesb0UIGlKgYsMeta?.alias || [],
    redirect: my_45favoritesb0UIGlKgYsMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searcho4hWCZOYBgMeta?.name ?? "new-home-search",
    path: new_45home_45searcho4hWCZOYBgMeta?.path ?? "/new-home-search",
    meta: new_45home_45searcho4hWCZOYBgMeta || {},
    alias: new_45home_45searcho4hWCZOYBgMeta?.alias || [],
    redirect: new_45home_45searcho4hWCZOYBgMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhousesd80V7RzPxMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhousesd80V7RzPxMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhousesd80V7RzPxMeta || {},
    alias: easyhousesd80V7RzPxMeta?.alias || [],
    redirect: easyhousesd80V7RzPxMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionXBetEJN0neMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionXBetEJN0neMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionXBetEJN0neMeta || {},
    alias: limitededitionXBetEJN0neMeta?.alias || [],
    redirect: limitededitionXBetEJN0neMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottagebpHyDG65w7Meta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottagebpHyDG65w7Meta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottagebpHyDG65w7Meta || {},
    alias: wee_45cottagebpHyDG65w7Meta?.alias || [],
    redirect: wee_45cottagebpHyDG65w7Meta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexVhQkBKJsXqMeta?.name ?? "our-homes",
    path: indexVhQkBKJsXqMeta?.path ?? "/our-homes",
    meta: indexVhQkBKJsXqMeta || {},
    alias: indexVhQkBKJsXqMeta?.alias || [],
    redirect: indexVhQkBKJsXqMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodslADk4kZwMpMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodslADk4kZwMpMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodslADk4kZwMpMeta || {},
    alias: our_45neighborhoodslADk4kZwMpMeta?.alias || [],
    redirect: our_45neighborhoodslADk4kZwMpMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45uscUov9RAWKzMeta?.name ?? "partner-with-us",
    path: partner_45with_45uscUov9RAWKzMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45uscUov9RAWKzMeta || {},
    alias: partner_45with_45uscUov9RAWKzMeta?.alias || [],
    redirect: partner_45with_45uscUov9RAWKzMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsaf8hB8tGLgMeta?.name ?? "privacyterms",
    path: privacytermsaf8hB8tGLgMeta?.path ?? "/privacyterms",
    meta: privacytermsaf8hB8tGLgMeta || {},
    alias: privacytermsaf8hB8tGLgMeta?.alias || [],
    redirect: privacytermsaf8hB8tGLgMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandbox1DB9IsOP8gMeta?.name ?? "sandbox",
    path: sandbox1DB9IsOP8gMeta?.path ?? "/sandbox",
    meta: sandbox1DB9IsOP8gMeta || {},
    alias: sandbox1DB9IsOP8gMeta?.alias || [],
    redirect: sandbox1DB9IsOP8gMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93L2lO8IN5jYMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93L2lO8IN5jYMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93L2lO8IN5jYMeta || {},
    alias: _91_46_46_46slug_93L2lO8IN5jYMeta?.alias || [],
    redirect: _91_46_46_46slug_93L2lO8IN5jYMeta?.redirect,
    component: () => import("/codebuild/output/src1885548339/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/build-with-us/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/career-opportunities/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/email-signup/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/get-updates/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/lifestyle/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/login/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/lost-password/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/maintenance-items/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/organism-page/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/register/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/reset-password/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/style-guide/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  },
  {
    name: component_45stubLrvdXmAaLrMeta?.name ?? undefined,
    path: component_45stubLrvdXmAaLrMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubLrvdXmAaLrMeta || {},
    alias: component_45stubLrvdXmAaLrMeta?.alias || [],
    redirect: component_45stubLrvdXmAaLrMeta?.redirect,
    component: component_45stubLrvdXmAaLr
  }
]